import React, { useState} from "react";
import {Box, List} from "react-bulma-components";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../../components/i18n";
import ActionsModal from "../common/actions-modal";
import { confirmDelete } from "../../../../../tools/confirm-delete";
import { handleDeleteIntegration } from "../utils";
import {parseJSON} from "../../../../../tools/error-handler";
import IntegrationControlHook from "../common/integration-control.hook";
import IntegrationListenerHook from "../common/integration-listener.hook";
import ModalItsOK from "../../../../../components/modal-its-ok";
import ErrorMessageBlock from "../../../../../components/error-message-block/error-message-block";
import FacebookButton from "./facebook-button";
import UserActions from "../../../../../user/user-actions";
import NumberInput from "../../../../../components/number-input/number-input";
import {Label} from "react-bulma-components/lib/components/form";
import yajax from "yajax";
import SubmitButton from "../../../../../components/submit-button";

const Facebook = ({ data, wooID, onClose }) => {
  const { id, is_active } = data;

  const ident = parseJSON(data.ident);
  const [waiting, setWaiting] = useState(false);
  const [valueInput, setValueInput] = useState(5)
  const [waitingDownload, setWaitingDownload] = useState(false)
  const { handleAutoRemoveError, setErrorMessage, errorMessage } =
    IntegrationControlHook({});

  const { submitOK, additionalMessage } = IntegrationListenerHook({
    handleError: handleAutoRemoveError,
    setWaiting,
  });

  const handleSubmit = ({deactivate}) => {
    const dataState = JSON.parse(JSON.stringify(data))

    dataState.ident = JSON.stringify(dataState.ident)
    if(deactivate) {
      dataState.is_active = 0;
    } else {
      dataState.is_active = 1
    }

    UserActions.updateIntegrations(dataState)
  }

  const handleDownloadDialogs = () => {
    setWaitingDownload(true)
    yajax.get('/bfb/rmessage',{iid: data.id, limit: valueInput})
    setTimeout(() => setWaitingDownload(false),3000)
  }

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      {!submitOK && (
        <Box> 
          <Heading size={3}>
            {T("setting-integration", [`Facebook / Instagram`])}
          </Heading>
          <List>
            {id && (
              <List.Item>
                {ident &&
                  ident?.pages.map((page) => (
                    <React.Fragment key={page.id}>
                    <Heading size={5} key={page.id}>
                      {T("Facebook")}:{" "}
                      <b>
                        {page["link"] ? <a
                          href={page["link"]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {page["name"]}
                        </a> : page["name"]}
                      </b>
                    </Heading>
                    {page.instagram && <Heading size={5} key={page.id}>
                      {T("Instagram")}:{" "}
                      <b>
                        {page["link"] ? <a
                          href={page.instagram.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {page.instagram?.name}
                        </a> : page.instagram?.name}
                      </b>
                    </Heading>}
                    </React.Fragment>
                  ))}
              </List.Item>
            )}
          </List>
          <div className='display-flex-column' style={{alignItems: "flex-start", gap: 5}}>
            <Label size='large'>{T('download-latest-dialogs')}</Label>
            <Label>
              {T('number-dialogs')}
            </Label>
            <div className='display-flex-row'>
              <NumberInput style={{maxWidth: 150}} max={50} value={valueInput} onChange={({target: { value } }) => setValueInput(value)} />
              <SubmitButton color='success' size='small' fullwidth={false} rounded text='download-label' submit={handleDownloadDialogs} waiting={waitingDownload}/>
            </div>
          </div>
          {!id && (
            <div className="display-flex-center">
                <FacebookButton wooID={wooID}/>
            </div>
          )}
          <ActionsModal
            onClose={onClose}
            onSubmit={handleSubmit}
            isEdit={!!id}
            isActive={is_active}
            waiting={waiting}
            onDelete={() => confirmDelete(() => handleDeleteIntegration(id))}
          />
        </Box>
      )}
      {submitOK && (
        <ModalItsOK
          onClose={onClose}
          title={submitOK}
          additionalMessage={additionalMessage}
        />
      )}
    </>
  );
};

export default Facebook;
