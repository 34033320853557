import React from "react";
import Level from "react-bulma-components/lib/components/level";
import OpenInsideModal from "../../../components/modal/open-inside-modal";
import EditSaleModal from "../edit-sales-modal";
import User from "../../../user/user-store";
import { Link } from "react-router-dom";
import T from "../../../components/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import Actions from "../sales-actions";
import SaledButton from "../common/buttons/saled-button";
import RefundButton from "../common/buttons/refund-button";
import ReservButton from "../common/buttons/reserv-button";
import CodButton from "../common/buttons/cod-button";
import DeliveryButton from "../common/buttons/delivery-button";
import FisclizationButton from "../common/buttons/fisclization-button";
import PrepaymentFiscalization from "../modal/prepayment-fiscalization/prepayment-fiscalization";
import AddSalesModal from "../add-sales-modal";
import PrintModal from "../print-modal";
import AppStore from "../../../app-store";
import {Button} from "react-bulma-components";
import ChangeStatusModal from "../change-status-modal";
import UserStore from "../../../user/user-store";

const deleteSale = (oid, _pr) => {
  confirmAlert({
    title: T("confirm-delete"),
    message: T("are-you-sure-to-delete-this"),
    buttons: [
      {
        label: T("delete"),
        onClick: () => Actions.deleteSale(oid, _pr),
      },
      {
        label: T("no-delete"),
        onClick: () => {},
      },
    ],
  });
};

const ActionsButtons = ({ status, name, data, isRefund }) => {
  const {
    account_id,
    oid,
    amount_sale,
    prepaid_amount,
    prapaid_account_id,
    dps,
    clients_delivery_id,
  } = data;

  if(isRefund) {
    return <Level>
      <Level.Item>
        <Button className='button-link-icon' onClick={() => AppStore.openModal(<EditSaleModal oid={data.oid} modal="sale-modal" isRefund/>)}>
          <FontAwesomeIcon icon='edit' size='2x'/>
        </Button>
      </Level.Item>
    </Level>
  }

  const renderCommonButtons = status !== "cod" && status !== "refund";

  let content_button;

  switch (status) {
    case "saled":
      content_button = (
        <SaledButton
          accountID={account_id}
          orderID={oid}
          productName={name}
          amount={amount_sale - prepaid_amount}
        />
      );
      break;
    case "reserve_sale":
      content_button = (
        <ReservButton
          prepaid={prepaid_amount}
          accountID={account_id}
          prepaidAccountID={prapaid_account_id}
          orderID={oid}
          totalOrder={amount_sale}
        />
      );
      break;
    case "cod":
      content_button = (
        <CodButton
          amount={amount_sale - prepaid_amount}
          accountID={account_id}
          orderID={oid}
        />
      );
      break;
    case "refund":
      content_button = (
        <RefundButton
          amount={amount_sale - prepaid_amount}
          accountID={account_id}
          prepaidAccountID={prapaid_account_id}
          productName={name}
          orderID={oid}
        />
      );
      break;
    default:
      content_button = (
        <DeliveryButton
          accountID={account_id}
          orderID={oid}
          totalOrder={amount_sale}
          prepaid={prepaid_amount}
          deliveryID={clients_delivery_id}
          prepaidAccountID={prapaid_account_id}
        />
      );
      break;
  }

  console.log(status)
  return (
    <>
      <Level breakpoint="mobile" className="is-mobile sales-buttons">
        {content_button}
        <FisclizationButton dps={dps} orderID={oid} accountID={account_id} />
      </Level>
      {User.isButtonPrepaymentDps({
        data: dps,
        prepaid: prepaid_amount,
        accountPrepaid: prapaid_account_id,
        orderID: oid
      }) && (
        <Level>
          <Level.Item className="sales-buttons-item">
            <OpenInsideModal
              className="margin-left-5"
              color="success"
              text={"send-to-dps-prepaid"}
            >
              <PrepaymentFiscalization
                oid={oid}
                amount={prepaid_amount}
                accountId={prapaid_account_id}
              />
            </OpenInsideModal>
          </Level.Item>
        </Level>
      )}
      <Level breakpoint="mobile" className="is-mobile sales-buttons">
        {User.getModuleEnable("slip") && <Level.Item>
          <OpenInsideModal
            renderAs="icon"
            color="success"
            icon="print"
            size='2x'
          >
              <PrintModal oid={oid} dps={data["dps"]}/>
          </OpenInsideModal>
          </Level.Item>}
        {renderCommonButtons && (
          <>
            {UserStore.getPermissionEnable("edit-sales") && <Level.Item>
              <OpenInsideModal
                renderAs="icon"
                icon="edit"
                size="2x"
                text="edit"
              >
                <EditSaleModal oid={oid} modal="sale-modal"/>
              </OpenInsideModal>
            </Level.Item>}
            <Level.Item>
              <OpenInsideModal renderAs="icon" size="2x" icon="copy" text={T("copy-sale")}>
                <AddSalesModal {...data} isCopy modal="sale-modal"/>
              </OpenInsideModal>
            </Level.Item>
            {User.getPermissionEnable("deletesales") && (
              <Level.Item>
                <Link
                  to="#"
                  onClick={() => deleteSale(oid)}
                  title={T("delete")}
                >
                  <FontAwesomeIcon
                    icon="trash-alt"
                    size="2x"
                    title={T("delete")}
                  />
                </Link>
              </Level.Item>
            )}
          </>
        )}
        {data["order_status"] === 'refund' && <Level.Item>
          <OpenInsideModal
            renderAs="icon"
            size='large'
            color="success"
            local_icon="circle-dotted"
          >
            <ChangeStatusModal
              modal="change-status"
              oid={data["oid"]}
              status={data["order_status"]}
            />
          </OpenInsideModal>
        </Level.Item>}
      </Level>
    </>
  );
};

export default ActionsButtons;
