import React, {useEffect, useState} from 'react'
import List from "react-bulma-components/lib/components/list";
import Button from "react-bulma-components/lib/components/button";
import T from "../../../../components/i18n";
import formatDate from "../../../../tools/format-date";
import UserActions from "../../../../user/user-actions";
import {confirmAlert} from "react-confirm-alert";
import {parseJSON} from "../../../../tools/error-handler";
import NotificationActions from "../../../notification/notification-actions";
import SubmitButton from "../../../../components/submit-button";
import UserStore from "../../../../user/user-store";
import {confirmDelete} from "../../../../tools/confirm-delete";
import {handleUpdateDownload, loadProductsConfirm} from "./utils";
import ProductsActions from "../../../products/products-actions";
import OrdersActions from "../../../orders/orders-actions";

const ActionsUploadDownloadProducts = ({data, isPutProducts, disabledPutProduct}) => {
	const [waiting, setWaiting] = useState(false)
	const [waitingStatus, setWaitingStatus] = useState(false)
	const {ident} = data

	const identParse = parseJSON(ident)

	useEffect(() => {
		const completedUpdateIntegrations = UserActions.updateIntegrations.completed.listen(() => {
			setTimeout(() => NotificationActions.loadMessages(), 2000)
		})
		return () => {
			completedUpdateIntegrations()
		}
	}, []);

	const downloadProductConfirm = () => {
		const buttons = [
		]

		if(!disabledPutProduct) {
			buttons.push({
				label: T('unload-all-products'),
				onClick: () =>  handleUpdateDownload(false, true, false, data, true)
			})
			buttons.push({
				label: T('upload-new-product'),
				onClick: () =>  handleUpdateDownload(false, true, false, data)
			})
		}

		if(identParse?.update_stock) {
			buttons.push({
				label: T('unload-only-stock'),
				onClick: () =>  handleUpdateDownload('only_stock', true, false, data)
			})
		}

		if(identParse?.update_price) {
			buttons.push({
				label: T('unload-only-price'),
				onClick: () =>  handleUpdateDownload('only_price', true,false, data)
			})
		}

		buttons.push({
			label: T('close'),
			onClick: () => {}
		})

		confirmAlert({
			title: T('export_products'),
			message: T('download-products-desc'),
			buttons
		});
	}

	const handleLoadCategory = () => {
		confirmDelete(() => {
			setWaiting(true)
			UserActions.downloadListCategories(data.id)
		}, 'download-list-categories', false, 'confirm-label', 'cancel-label')
	}

	const handleLoadStatus = () => {
		confirmDelete(() => {
			setWaitingStatus(true)
			UserActions.downloadListIntegrationStatus(data.id)
		}, 'download-integration-status', false, 'confirm-label', 'cancel-label')
	}

	useEffect(() => {
		const loadDownloadList = UserActions.downloadListCategories.completed.listen(() => {
			setWaiting(false)
			setTimeout(() => {
				ProductsActions.loadCategory();
				NotificationActions.loadMessages()
			}, 2000)
		})
		const loadDownloadListFailed = UserActions.downloadListCategories.failed.listen(() => {
			setWaiting(false)
			setTimeout(() => NotificationActions.loadMessages(), 2000)
		})

		const loadDownloadListStatus = UserActions.downloadListIntegrationStatus.completed.listen(() => {
			setWaiting(false)
			setTimeout(() => {
				OrdersActions.loadStatus();
				NotificationActions.loadMessages()
			}, 2000)
		})
		const loadDownloadListStatusFailed = UserActions.downloadListIntegrationStatus.failed.listen(() => {
			setWaiting(false)
			setTimeout(() => NotificationActions.loadMessages(), 2000)
		})

		return () => {
			loadDownloadListFailed()
			loadDownloadList()
			loadDownloadListStatus()
			loadDownloadListStatusFailed()
		}
	}, []);

	return (
		<List>
			<List.Item align='left'>
				<SubmitButton submit={handleLoadStatus}
											text='download-integration-status' fullwidth={false} rounded
											size='small' color='info' waiting={waitingStatus}/>
			</List.Item>
			{data.integration_id === UserStore.HS_ID && <List.Item align='left'>
				<SubmitButton submit={handleLoadCategory}
											text='download-list-categories' fullwidth={false} rounded
											size='small' color='info' waiting={waiting}/>
			</List.Item>}
			{isPutProducts && (
				<List.Item className='display-flex-row-gap'>
					 <Button rounded color="primary" size="small"
									 disabled={!identParse?.update_price && !identParse?.update_stock && disabledPutProduct}
									 onClick={downloadProductConfirm}
					 >
						{T('export_products')}
					</Button>
				{identParse?.last_put_products && <span>
						<b>{T('last-put-date')}:</b> {formatDate(identParse?.last_put_products)}
				</span>}
			</List.Item>)}
			<List.Item className='display-flex-row-gap'>
				<Button rounded color="success" size="small" onClick={() => loadProductsConfirm(data)}>
					{T('btn_load_products')}
				</Button>
				{identParse?.last_download_product && (
					<span>
						<b>{T('last-download-date')}:</b> {formatDate(identParse?.last_download_product)}
					</span>)}
			</List.Item>
		</List>
	);
};

export default ActionsUploadDownloadProducts;