import React, {useState} from 'react'
import T from "../../../../components/i18n";
import ProductStore from "../../products-store";
import User from "../../../../user/user-store";
import Image from "react-bulma-components/lib/components/image";
import UserStore from "../../../../user/user-store";
import SalesStore from "../../../sales/sales-store";
import LightActions from "../../light-actions";
import LightStore from "../../light-store";
import Media from "react-bulma-components/lib/components/media";
import {
	Checkbox,
	Control,
	Field
} from "react-bulma-components/lib/components/form";
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import EditProductsModal from "../../edit-products-modal";
import Content from "react-bulma-components/lib/components/content";
import ExpirationDateTag
	from "../../../../components/expiration-date-tag/expiration-date-tag";
import Level from "react-bulma-components/lib/components/level";
import {Link} from "react-router-dom";
import {
	deleteProduct,
	getStockDesktop,
} from "../../common/function";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Box from "react-bulma-components/lib/components/box";
import ColorControl from "../../../../tools/color-control";
import NewProductRow from "./new-product-row";
import EditVariableModal from "../../modal/add-product/edit-variable-modal";
import TypeProductTag
	from "../../../../components/type-product-tag/type-product-tag";

const NewProductRowStock = ({data, backgroundLightBlack, isVariable}) => {
	const [isShow, setIsShow] = useState(false);


	const handleToggleShow = () => {
		setIsShow((prev) => !prev);
	};

	const info = [];
	info.push("ID: " + data.id);
	if (data["brand"] && data["brand"] !== "") {
		info.push(T("product-brand") + ": " + data["brand"]);
	}
	if (data["category_id"] && data["category_id"] !== "") {
		info.push(
			T("category") + ": " + ProductStore.getCategoryName(data["category_id"])
		);
	}

	let img = "/bimages/get/64-" + User.get("id") + "-" + data.id + "-0.jpg";

	if (ProductStore.needRemoveImageCache(data.id)) {
		img += "?" + Math.random();
	}

	const img_o = <Image src={img} size={64} />;

	const sumInstock = Object.values(data.stock || {}).reduce(
		(acc, cur) => {
			acc.quantity += cur.quantity;
			acc.waiting += cur.waiting;
			acc.instock += cur.instock;

			const isMinMax = UserStore.isMinimalStock({mid: cur.marketplace_id, pid: cur.product_id, instock: cur.instock});

			if(isMinMax) {
				acc.isMinMaxStock = true
			}

			const salesReport = SalesStore.getSaleReportsByProduct(
				cur.product_id,
				cur.marketplace_id
			);
			acc.items_count += salesReport.items_count;

			return acc;
		},
		{ waiting: 0, instock: 0, quantity: 0, items_count: 0, isMinMaxStock: false }
	);

	const handleChangeGroupCheckbox = (evt) => {
		const checked = evt.target.checked;
		Object.values(data.stock).forEach((item) => {
			LightActions.setProductGroup(
				[item.product_id, item.marketplace_id],
				checked
			);
		});
	};
	const handleIsCheckedProduct = () => {
		return Object.values(data.stock || {}).every((item) =>
			LightStore.getProductGroupCheckbox([item.product_id, item.marketplace_id])
		);
	};
	const isChecked = handleIsCheckedProduct();

	const expirationDate = ProductStore.theExpirationDateByPid(data.id)


	const name = ProductStore.getProductName(data.id)

	const handleDelete = () => {
		const warehouseKey = Object.values(data.stock).map(item => `${data.id}_${item.marketplace_id}`)
		deleteProduct(warehouseKey)
	}

	let classNameBorder = ''

	if(data.type_product === 4) {
		classNameBorder = 'services-border';
	} else if(data.type_product === 3) {
		classNameBorder = 'bundle-border'
	}

	return (
		<>
			<tr key={"pid-"} className={`cursor-pointer ${expirationDate ? expirationDate : ''} ${backgroundLightBlack ? "has-background-light-black" : ''}`} onClick={handleToggleShow}>
				<td className={classNameBorder}>
					<Media>
						<Media.Item position="left" className='position-relative'>
							<Field className="absolute">
								<Control>
									<Checkbox
										name="is_parent_products"
										onChange={handleChangeGroupCheckbox}
										onClick={(evt) => evt.stopPropagation()}
										checked={isChecked}
									/>
								</Control>
							</Field>
							{!User.getPermissionEnable("editproducts") ? (
								<div className="product-name">{img_o}</div>
							) : (
								<OpenInsideModal
									className="product-name"
									size="small"
									text=""
									img={img_o}
									renderAs="b"
								>
									<EditProductsModal pid={data.id} step={1} />
								</OpenInsideModal>
							)}
						</Media.Item>
						<Media.Item>
							<Content>
								<p className="product-name">
									<b><ExpirationDateTag pid={data.id}/></b>
									<b><TypeProductTag
										typeProduct={data.type_product}/> {name} ({T('warehouse-count', {count: Object.keys(data.stock).length})})</b>
								</p>
								<p>{info.join(" | ")}</p>
							</Content>
							<Level renderAs="nav" className="margin-top-10">
								<Level.Side align="left">
									{User.getPermissionEnable("editproducts") && (
										<Level.Item>
											<OpenInsideModal size="small" text="edit-btn">
												{isVariable ? (
													<EditVariableModal pid={data.id} parent_id={data.parent_id}/>
												) : (
													<EditProductsModal pid={data.id} step={0} />
												)}
											</OpenInsideModal>
										</Level.Item>
									)}
									{User.getPermissionEnable("deleteproducts") && (
										<Level.Item>
											<Link
												to="#"
												onClick={handleDelete}
												title={T("delete")}
											>
												<FontAwesomeIcon
													icon={"trash-alt"}
													size={"1x"}
													title={T("delete")}
												/>
											</Link>
										</Level.Item>
									)}
								</Level.Side>
							</Level>
						</Media.Item>
					</Media>
				</td>
				<td>
					{data.type_product === 4 ?
						(
							<Box className="padding-left-right5">
								<div>{T('available')}</div>
								{ColorControl("∞")}
								<div>{T(data["units"])}</div>
							</Box>
						) : (
							<Box
								style={sumInstock.isMinMaxStock ? {border: '1px solid red'} : {}}>
								<div>{T("available")}</div>
								<strong>
									{getStockDesktop(
										sumInstock.instock,
										sumInstock.quantity,
										sumInstock.waiting,
										false,
										false,
										sumInstock.instock !== 0 && sumInstock.instock % 1 !== 0 && true
									)}
								</strong>
								<div>{T(data["units"])}</div>
							</Box>)}
				</td>
				{!User.getPermissionEnable("incomeinformation") ? null : (
					<td>
						<Box className="padding-bottom0">
							<div>{T("quantity")}</div>
							{ColorControl(sumInstock.items_count)}
							<div>{T("of-sales")}</div>
							<div className="small-font margin-5">
								{T("with")} {SalesStore.getStartFrom()}
							</div>
						</Box>
					</td>
				)}
				<td colSpan={3}></td>
			</tr>
			{isShow && (
				<>
					{Object.values(data.stock).map((item) => {
						return (
							<NewProductRow
								key={`${item.marketplace_id}_${item.product_id}`}
								data={data}
								stock={item}
								backgroundLightBlack
								backgroundStrongBlack={backgroundLightBlack}
								isVariable={isVariable}
							/>
						);
					})}
				</>
			)}
		</>
	);
};

export default NewProductRowStock;