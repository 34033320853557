import React from 'react'
import Image from "react-bulma-components/lib/components/image";
import UserStore from "../../../../user/user-store";
import ProductStore from "../../products-store";
import ExpirationDateTag
	from "../../../../components/expiration-date-tag/expiration-date-tag";
import Media from "react-bulma-components/lib/components/media";
import {
	Checkbox,
	Control,
	Field
} from "react-bulma-components/lib/components/form";
import LightStore from "../../light-store";
import User from "../../../../user/user-store";
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import EditProductsModal from "../../edit-products-modal";
import Content from "react-bulma-components/lib/components/content";
import {deleteProduct, showAdditionalField} from "../../common/function";
import Level from "react-bulma-components/lib/components/level";
import Button from "react-bulma-components/lib/components/button";
import SalesActions from "../../../sales/sales-actions";
import T from "../../../../components/i18n";
import AppStore from "../../../../app-store";
import ViewProductModal from "../../modal/view-product/view-product-modal";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import WarehouseStore from "../../../warehouse/warehouse-store";
import Box from "react-bulma-components/lib/components/box";
import ColorControl from "../../../../tools/color-control";
import ChangeStockModal from "../../change-stock-modal";
import SalesStore from "../../../sales/sales-store";
import CostField from "../../common/cost-field";
import getCalcNetPrice from "../../common/calc-netprice";
import Money from "../../../../components/money";
import ChannelBlock from "../../../../components/channel-block/channel-block";
import BindingIntegrationProductsModal
	from "../../binding-integration-products-modal";
import { LocalIcon } from 'whitelables/wl-name/icons'
import calcMargin from "../../../../tools/calc-margin";
import CheckReservShipModal from "../../check-reserv-ship-modal";
import LightActions from "../../light-actions";
import EditVariableModal from "../../modal/add-product/edit-variable-modal";
import TypeProductTag
	from "../../../../components/type-product-tag/type-product-tag";

const GetStock = ({isFixed, isMinMax, instock, quantity, waiting, pid, mid}) => {
	let value = isFixed ? instock?.toFixed(3) : instock;
	if (instock !== quantity) {
		value = <span>{instock + "/" + quantity}</span>;
	}
	if(waiting) {
		value = (
			<span>{value} <span className="text-warning">({waiting})</span></span>
		)
	}

	const useSmallFont = instock !== quantity && waiting && true
	return (
		<span className={instock !== quantity || waiting ? 'cursor-pointer zoom-hover' : ''} onClick={instock !== quantity || waiting ? () => AppStore.openModal(<CheckReservShipModal pid={pid} reservLength={quantity - instock} mid={mid} realQuantity={quantity}/>) : () => {}}>
                 {ColorControl(value, instock !== 0 && quantity !== 0, null, null, useSmallFont, isMinMax)}
          </span>
	)
}

const IntegrationItem = ({mp, pid, mid}) => {
	let integraions = [];
	for (let i in mp) {
		let iid = mp[i];
		let channel_id = SalesStore.foundChannelsByIntegrationId(iid);


		const isMidInIntegration = UserStore.isIntegrationWithWarehouse({mid,iid})
		if(!isMidInIntegration) continue

		integraions.push(
			<Level.Item key={'ii-'+pid + iid} title={T('binding-edit-title')}>
				<ChannelBlock channel_id={channel_id} onClick={() => {
					User.isAdmin() ?  AppStore.openModal(<BindingIntegrationProductsModal modal='binding-modal' product_id={pid} product_mid={mid} integration_id={iid}/>) : console.log("false")}}/>
			</Level.Item>
		)
	}
	return integraions
}

const IntegrationButton = ({pid, mid}) => {
	return (
		<Level.Item key={`binding-${pid}`} title={T('binding-add-title')}>
			<Link
				to='#'
				onClick={() => AppStore.openModal(<BindingIntegrationProductsModal modal='binding-modal' product_id={pid} product_mid={mid} isCreate={true}/>)}
			>
				<LocalIcon icon='integration' size='small'/>
			</Link>
		</Level.Item>)
}


const NewProductRow = ({data, stock, backgroundLightBlack, backgroundStrongBlack, isVariable}) => {
	const {id, type_product, af, mp, units, sale_price, currency_id, name, full_name} = data

	const {instock, marketplace_id, net_price, id:wid, price, waiting, quantity} = stock

	const srcImage = "/bimages/get/64-" + User.get("id") + "-" + data.id + "-0.jpg";
	const img_o = (
		<Image src={srcImage} size={64} />
	);
	const isMinMax = UserStore.isMinimalStock({mid: marketplace_id,pid: id, instock})
	const expirationDate = ProductStore.theExpirationDateByPid(id)

	let product_name = (
		<>
			<ExpirationDateTag pid={id}/>{" "}
			<TypeProductTag typeProduct={type_product}/>
			{isVariable ? `ID: ${data.id}. ${name}` : name}</>)

	const report = SalesStore.getSaleReportsByProduct(id, marketplace_id)

	const margin = calcMargin(
		net_price,
		price,
		sale_price
	);

	const handleChangeCheckbox = (evt) => {
			const value = evt.target.checked;

			LightActions.setProductGroup([id, marketplace_id], value);
	}


	const variableIds = isVariable ? ProductStore.getIdsProductVariable(data.parent_id) : null;
	let disabledDelete = false;

	if(data.parent_id === data.id) {
		if(variableIds.length > 1) {
			disabledDelete = true;
		}
	}

	let classNameBorder = ''

	if(type_product === 4) {
		classNameBorder = 'services-border';
	} else if(type_product === 3) {
		classNameBorder = 'bundle-border'
	}

	return (
		<tr key={"pid-" + id}
				style={backgroundStrongBlack ? {} : {}}
				className={`${backgroundLightBlack && !backgroundStrongBlack  ? "has-background-light-black" : backgroundStrongBlack ? 'has-background-strong-black' : ''} ${expirationDate ? expirationDate : ''}`}>
			<td className={classNameBorder}>
				<Media>
					<Media.Item position="left" style={{position: 'relative'}}>
						<Field className="absolute">
							<Control>
								<Checkbox
									name="is_products"
									onChange={handleChangeCheckbox}
									checked={LightStore.getProductGroupCheckbox([id, marketplace_id])}
								/>
							</Control>
						</Field>
						{!User.getPermissionEnable("editproducts") ?
							<div className="product-name">{img_o}</div>
							:
							<OpenInsideModal className="product-name" size="small" text='' img={img_o} renderAs="b">
								<EditProductsModal pid={id} step={1}/>
							</OpenInsideModal>
						}
					</Media.Item>
					<Media.Item>
						<Content>
							{User.getPermissionEnable("editproducts") ?
								isVariable ? (
									<OpenInsideModal className="product-name" size="small"
																	 text={product_name} renderAs="b">
										<EditVariableModal pid={data.id} parent_id={data.parent_id}/>
									</OpenInsideModal>
								) : (
									<OpenInsideModal className="product-name" size="small"
																	 text={product_name} renderAs="b">
										<EditProductsModal pid={id} step={0}/>
									</OpenInsideModal>
								) : <div className="product-name">{product_name}</div>
							}

							<p>{ProductStore.makeInfoBar(id, marketplace_id)}</p>
							{showAdditionalField(af)}
						</Content>
						<Level renderAs="nav">
							<Level.Side align="left">
								{User.isCanAddSale() &&
									<Level.Item>
										<Button
											size="small" rounded color="info"
											onClick={() => SalesActions.openSaleModal({"select_product": {'value': ProductStore.getIdent(id, marketplace_id), 'label': full_name }})}
										>{T('add-sale-btn')}</Button>
									</Level.Item>
									}
								<Level.Item>
									<Button size='small' rounded color='info' onClick={() => AppStore.openModal(<ViewProductModal pid={id} mid={marketplace_id}/>)}>
										{T('info')}
									</Button>
								</Level.Item>
								{User.getPermissionEnable("editinstock") && !isVariable &&
									<Level.Item>
										<OpenInsideModal size="1x" renderAs="icon" icon={"copy"} text={T('copy-product-title')}>
											<EditProductsModal pid={id} step={0} copy={true}/>
										</OpenInsideModal>
									</Level.Item>
								}

								{!disabledDelete && User.getPermissionEnable("deleteproducts") &&
									<Level.Item>
										<Link to="#" onClick={() => deleteProduct(id, marketplace_id)} title={T('delete')}>
											<FontAwesomeIcon icon={"trash-alt"} size="1x" title={T('delete')}/>
										</Link>

									</Level.Item>
								}
							</Level.Side>
							<Level.Side align="right"  className="product-integration">
								{!!mp.length && <IntegrationItem mp={mp} pid={id} mid={marketplace_id}/>}
								{User.getProductIntegrationModuleEnable(mp.length) && <IntegrationButton pid={id} mid={marketplace_id}/>}
							</Level.Side>
						</Level>

					</Media.Item>
				</Media>
			</td>
			<td >
				{WarehouseStore.isDropshipping(marketplace_id) || type_product === 4 ?
					<Box className="padding-left-right5" style={{minWidth: 130}}>
						<div>{T('available')}</div>
						{ColorControl("∞")}
						<div>{T(units)}</div>
					</Box>
					:
					<Box className={User.getPermissionEnable("editinstock")  && type_product !== 3 ? "padding-bottom0 padding-left-right5" : null} style={isMinMax ? {border: "1px solid red", minWidth: 130} : {minWidth: 130}}>
						<div>{T('available')}</div>
						<GetStock isFixed={instock !== 0 && instock % 1 !== 0} isMinMax={isMinMax} pid={id} mid={marketplace_id} instock={instock} waiting={waiting} quantity={quantity}/>
						<div>{T(units)}</div>
						{User.getPermissionEnable("editinstock") && type_product !== 3 ?
							<OpenInsideModal size="small" text={T('change-stock-button')} renderAs="a">
								<ChangeStockModal pid={id} mid={marketplace_id}/>
							</OpenInsideModal> : null}
					</Box>
				}
			</td>
			{User.getPermissionEnable("incomeinformation") &&
				<>
					<td>
						<Box className="padding-bottom0" style={{minWidth: 130}}>
							<div>{T('quantity')}</div>
							{ColorControl(report['items_count'])}
							<div>{T('of-sales')}</div>
							<div className="small-font margin-5">{T('with')} {SalesStore.getStartFrom()}</div>
						</Box>
					</td>
					<td align="center" valign="middle">
						<CostField cur_id={currency_id || User.get("currency_id")}
											 net_price={net_price} wid={wid}
											 pid={id} mid={marketplace_id}
											 subchild={type_product !== 3 ? getCalcNetPrice(id, marketplace_id, net_price) : null}
						/>
					</td>
				</>
			}
			{!User.getPermissionEnable("salesprice") ? null :
				<td align="center" valign="middle">
					<CostField
						cur_id={currency_id || User.get("currency_id")}
						price={price}
						sale_price={sale_price}
						wid={wid}
						pid={id}
						mid={marketplace_id}
					/>
				</td> }
			{User.getPermissionEnable("incomeinformation") && User.getPermissionEnable("salesprice") && (
				<td>
					<Box className="med-number-box" style={{minWidth: 130}}>
						<div>{T('margin')}</div>
						<div className="big-number text-success">
							<Money amount={(sale_price || price)-net_price } />
							({margin}%)<br />
						</div>
					</Box>
				</td>
			)}
		</tr>
	);
};

export default NewProductRow;