import React from 'react'
import Button from "react-bulma-components/lib/components/button";
import T from "../../../../../components/i18n";
import List from "react-bulma-components/lib/components/list";
import Columns from "react-bulma-components/lib/components/columns";

const ProductLineTitleDesktop = ({received, moveQuantityAll}) => {
	const quantity = received ? "quantity-res-mob" : "quantity-ord-mob",
		all = (
			<Button
				className="button_link smll"
				onClick={moveQuantityAll}
			>
				({T("all")})
			</Button>
		);
	return (
		<List.Item className="font-size-80 bold has-background-light" style={{
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-start",
		}}>
			<div style={{minWidth: 34}}/>
			<Columns style={{alignItems: 'center', flex: 1}}>
				<Columns.Column size={"half"}>{T("product-name")}</Columns.Column>
				{received ? <Columns.Column>{T("expect")}</Columns.Column> : ""}
				<Columns.Column>
					{T(quantity)}
					{received ? all : null}
				</Columns.Column>
				<Columns.Column>{T("shipments-price-item")}</Columns.Column>
				<Columns.Column>{T("products-price")}</Columns.Column>
				<Columns.Column>{T("cost-of-sales")}</Columns.Column>
				<Columns.Column
					size={1}
					style={{ width: "fit-content" }}
				></Columns.Column>
			</Columns>
		</List.Item>
	);
};

export default ProductLineTitleDesktop;