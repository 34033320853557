import React from "react";
import Columns from "react-bulma-components/lib/components/columns";
import Image from "react-bulma-components/lib/components/image";
import User from "../../../../../user/user-store";
import OpenInsideModal from "../../../../../components/modal/open-inside-modal";
import EditProductsModal from "../../../../products/edit-products-modal";
import ProductsStore from "../../../../products/products-store";
import { Control } from "react-bulma-components/lib/components/form";
import NumberInput from "../../../../../components/number-input/number-input";
import Icon from "react-bulma-components/lib/components/icon";
import Money from "../../../../../components/money";
import { Link } from "react-router-dom";
import T from "../../../../../components/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { productNameByStep } from "../utils";
import UserStore from "../../../../../user/user-store";

const ProductLineDesktop = ({
  received,
  id,
  currency,
  countName,
  step,
  mid,
  productData,
  currencyRate,
  moveQuantity,
  onChangeCount,
  onChangeProductData,
  onChangeRetailPrice,
  deleteShipmentItem,
  refresh,
}) => {
  const pid = id
  const prod = productData[id];

  if(!prod) {
    return null
  }

  const name = productNameByStep({ prod, step, currency });
  const count_name = countName;
  const img = "/bimages/get/64-" + User.get("id") + "-" + pid + "-0.jpg";
  const getProduct = ProductsStore.getProductByMid(pid, mid);

  const shCurr = currency;
  const retailCurr = User.getCurrency();

  let sumCostAmount =
    productData[pid][count_name] * (productData[pid]["raw_net_price"] || 0);

  if (shCurr !== retailCurr) {
    sumCostAmount =
      productData[pid][count_name] *
      ((productData[pid]["raw_net_price"] || 0) * currencyRate);
  }

  return (
    <Columns style={{alignItems: 'center'}}>
      <Columns.Column size={"half"} align="left">
        <Image src={img} size={32} className="align-left" key={refresh} />
        {User.getPermissionEnable("editproducts") ? (
          <OpenInsideModal size="small" text={name} renderAs="b">
            <EditProductsModal
              pid={getProduct.parent_id || getProduct.id}
              step={0}
            />
          </OpenInsideModal>
        ) : (
          name
        )}
      </Columns.Column>
      {received ? (
        <Columns.Column>
          <span className="like-link" onClick={() => moveQuantity(pid)}>
            {ProductsStore.getStockUnits(
              productData[pid]["quantity_shipped"],
              productData[pid]["units"]
            )}
          </span>
        </Columns.Column>
      ) : (
        ""
      )}
      <Columns.Column>
        <Control iconRight>
          <NumberInput
            name={"count-pid-" + pid}
            className="for-small-icon"
            onChange={onChangeCount}
            value={productData[pid][count_name]}
          />
          <Icon align="right">
            <span className="small-text">
              {ProductsStore.getUnits(productData[pid]["units"])}
            </span>
          </Icon>
        </Control>
      </Columns.Column>
      <Columns.Column>
        <Control iconRight>
          <NumberInput
            name={"pid-" + pid}
            className="for-small-icon"
            type="text"
            onChange={(evt) => onChangeProductData(evt, "raw_net_price")}
            value={productData[pid]["raw_net_price"]}
          />
          <Icon align="right">
            <span className="small-text">{currency}</span>
          </Icon>
        </Control>
      </Columns.Column>
      <Columns.Column>
        <Control iconRight>
          <NumberInput
            name={"retail_price-" + pid}
            className="for-small-icon"
            type="text"
            onChange={onChangeRetailPrice}
            value={productData[pid]["retail_price"]}
          />
          <Icon align="right">
            <span className="small-text">{UserStore.getCurrency()}</span>
          </Icon>
        </Control>
      </Columns.Column>
      <Columns.Column
        className="display-flex-center"
        style={{ height: "auto" }}
      >
        <Money amount={sumCostAmount} />
      </Columns.Column>
      <Columns.Column
        size="one-fifth"
        className="text-align-center"
        style={{ width: "fit-content" }}
      >
        <Link
          to="#"
          onClick={() => deleteShipmentItem(pid)}
          title={T("delete")}
        >
          <FontAwesomeIcon icon="trash-alt" size="1x" />
        </Link>
      </Columns.Column>
    </Columns>
  );
};

export default ProductLineDesktop;
