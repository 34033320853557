import React, { useEffect, useState } from "react";
import { Box, Heading } from "react-bulma-components";
import ProductsStore from "../../products-store";
import DescriptionBlock from "./description/description-block";
import DynamicTabList from "../../../../components/dynamic-tab-list/dynamic-tab-list";
import SalesStore from "../../../sales/sales-store";
import SalesProductBlock from "./sales-product/sales-product-block";
import { connectToStores } from "../../../../tools/reflux-tools";
import AddProductBlock from "./add-product/add-product-block";
import ShipmentProductBlock from "./shipment-product/shipment-product-block";
import MovingProductBlock from "./moving-product/moving-product-block";
import ProductsActions from "../../products-actions";
import WriteOffProductBlock from "./write-off-product/write-off-product-block";
import T from '../../../../components/i18n'
import ShipmentsStore from "../../../shipments/shipments-store";
import ShipmentsActions from "../../../shipments/shipments-actions";
import ActionButtons from "./common/action-buttons";
import HistoryProductBlock from "./history-product/history-product-block";
import UserStore from "../../../../user/user-store";

const tabList = [
  { label: "description", value: "description",},
  { label: "sales", value: "sales", module: 'sales' },
  { label: "Shipments", value: "Shipments", module: 'shipments'},
  { label: "added", value: "added" },
  { label: "moving", value: "moving", module: 'moving' },
  { label: "write-off-label", value: "write-off-label", module: 'write-off' },
  { label: "history", value: "history", module: 'history' },
];

const ViewProductModal = ({ pid,mid, onClose }) => {
  const [isActiveTab, setIsActiveTab] = useState("description");
  const data = ProductsStore.getProduct(pid);
  const handleChangeTab = (value) => {
    setIsActiveTab(value);
  };

  useEffect(() => {
    if(pid) {
      ProductsActions.loadHistory(pid);
      ShipmentsActions.load()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!data) {
    onClose && onClose()
    return null
  }

 if(!pid) {
   return <Box>{T('error-payment')}</Box>
 }
  const firstMid = Object.keys(data.stock)[0];

 const filterWithPermission = tabList.filter(item => {
   if(item.module) {
     return UserStore.getModuleEnable(item.module)
   } else {
     return true
   }
 })

  return (
    <Box>
      <Heading>
        ID {pid}: {data.name}
      </Heading>
      <DynamicTabList
        defaultTab={isActiveTab}
        list={filterWithPermission}
        onChangeTab={handleChangeTab}
      />
      {isActiveTab === "description" && <DescriptionBlock pid={pid} integrationList={data.mp}/>}
      {UserStore.getModuleEnable('sales') && isActiveTab === "sales" && (
        <SalesProductBlock pid={pid} units={data.units} />
      )}
      {UserStore.getModuleEnable('shipments') && isActiveTab === "Shipments" && (
        <ShipmentProductBlock pid={pid} units={data.units} />
      )}
      {UserStore.getModuleEnable('moving') && isActiveTab === "moving" && (
        <MovingProductBlock pid={pid} units={data.units} />
      )}
      {isActiveTab === "added" && (
        <AddProductBlock pid={pid} units={data.units} />
      )}
      {UserStore.getModuleEnable('write-off') && isActiveTab === "write-off-label" && <WriteOffProductBlock units={data.units} />}
      {UserStore.getModuleEnable('history') && isActiveTab === "history" && <HistoryProductBlock pid={pid}/>}
      <ActionButtons isActiveTab={isActiveTab} onClose={onClose} pid={pid} parentId={data?.parent_id} mid={mid || firstMid}/>
    </Box>
  );
};

export default connectToStores(ViewProductModal, {
  sales: SalesStore,
  products: ProductsStore,
  shipments: ShipmentsStore,
});
