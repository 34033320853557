import React, {useEffect, useState} from 'react'
import HistoryStore from "../../../../history/history-store";
import {connectToStores} from "../../../../../tools/reflux-tools";
import HistoryProductRow from "./history-product-row";
import {Box, Button, Heading, List} from "react-bulma-components";
import DateRange from "../../../../../components/base-filter-layout/date-range";
import HistoryActions from "../../../../history/history-actions";
import T from "../../../../../components/i18n";
import {applyFilter} from "../utils";
import usePaginationHook from "../../../../../tools/pagination-hook";
import {parseJSON} from "../../../../../tools/error-handler";

const HistoryProductBlock = ({pid}) => {
	const historyData = HistoryStore.getListHistory()
	const [triggerUnfoldReport, setTriggerUnfoldReport] = useState(false)

	const filterHistoryData = historyData.filter(item => {
		if(parseInt(item.product_id) === parseInt(pid)) {
			return true
		}

		const parseComment = parseJSON(item.comment)

		if(parseComment?.pid_mid){
			return parseComment.pid_mid.split(',').some(ident => {
				const splitIdent = ident.split('_')
				return parseFloat(pid) === parseFloat(splitIdent[0])
			})
		}

		return false
	})

	const filteredDate = filterHistoryData.filter((data) =>
		applyFilter(data, "created_at", false, HistoryStore, false, true)
	);

	const {PaginationBlock,setPage,start,end,page,total} = usePaginationHook({ listLength: filteredDate.length, perPage: 10 });

	const handleChangeDate = (value) => {
		const daysKey = Object.keys(value).reverse();
		daysKey.forEach((item) => {
			HistoryActions.filter(item, value[item]);
		});
	};

	useEffect(() => {
		HistoryActions.load()
		return () => {
			HistoryStore.clearFilter()
		}
	}, []);

	return (
		<>
			<Box
				style={{
					display: "flex",
					justifyContent: "flex-end",
					borderRadius: "0 0 16px 16px",
				}}
			>
				<DateRange
					date_start={HistoryStore.getFilter("date_start")}
					date_end={HistoryStore.getFilter("date_end")}
					onChange={handleChangeDate}
				/>
			</Box>
			<div align='right'>
				<Button className='button-link-without-style' onClick={() => setTriggerUnfoldReport(prev => !prev)}>{triggerUnfoldReport ? T('hide-more-filter') : T('unfold')}</Button>
			</div>
			<List>
				{filteredDate.slice(start, end).map((item) => <HistoryProductRow triggerOpen={triggerUnfoldReport} key={item.id}
																											 data={item}/>)}
				{filteredDate.length === 0 && (
					<div align="center" className="margin-10">
						<Heading size={4}>{T("not-have-history")}</Heading>
					</div>
				)}
			</List>
			<div className="display-flex-center">
				<PaginationBlock
					totalPage={total}
					setPage={setPage}
					currentPage={page}
					showPrevNext={false}
				/>
			</div>
		</>
	);
};

export default connectToStores(HistoryProductBlock, {_: HistoryStore});