import React from 'react'
import List from "react-bulma-components/lib/components/list";
import Columns from "react-bulma-components/lib/components/columns";
import {Link} from "react-router-dom";
import Image from "react-bulma-components/lib/components/image";
import T from "../../../components/i18n";
import User from "../../../user/user-store";
import Money from "../../../components/money";
import DOM from "react-dom-factories";
import calcMargin from "../../../tools/calc-margin";
import RefundProductButton from "../common/refund-product-button";
import ProductsStore from "../../products/products-store";
import AppStore from "app-store";
import {notIsBasicType} from "../common/utils";
import ViewProductModal
	from "../../products/modal/view-product/view-product-modal";
import WarehouseStore from "../../warehouse/warehouse-store";
import TypeProductTag
	from "../../../components/type-product-tag/type-product-tag";
import UserStore from "../../../user/user-store";


const SalesProductRowMobile = ({data, accountID, orderID, status, productsLength, isRefundButton}) => {
	const {product_id,mid, quantity, product_deleted, is_refund, net_price, price, amount,base_price, profit, discount, type_price} = data

	const img = "/bimages/get/64-"+User.get("id")+"-"+product_id+"-0.jpg"

	const product = ProductsStore.getProductByMid(product_id,mid)

	const product_name = product.name

	let text_deleted = ''
	if(product_deleted || (product && !product.stock[mid])) {
		text_deleted = <span className='has-text-danger'> {T('product_deleted')}. </span>
	}

	let className = "is-mobile";
	if (is_refund) className += " opacity"


	const margin = calcMargin(net_price, amount)

	const sales_text = (
		<Columns className={className + " margin-bottom5 small-padding"} align='left'>
			<Columns.Column className="padding-bottom0">
				{UserStore.getPermissionEnable("salesprice") && <>
					<b>{T('cost-for')}:</b> <Money amount={price || 0} aid={accountID}
																				 wrapper={DOM.span}/><br/>
				</>}
				{!!discount && <><b>{T('sales-discount')}: </b> {discount < 0 ?
					<Money aid={data["account_id"]} amount={Math.abs(discount) || 0} wrapper={DOM.span} /> : `${discount}%`}</>}
			</Columns.Column>
			<Columns.Column className="padding-bottom0">
				{UserStore.getPermissionEnable("salesprice") && <>
					<b>{T('total')}:</b> <Money amount={amount || 0}
																			wrapper={DOM.span}/><br/>
				</>}
				{!!type_price && notIsBasicType(type_price) && <>
					<b>{T('name-type-price')}: </b><span
					className='nowrap'>{type_price}</span></>}
			</Columns.Column>
		</Columns>
	)

	const profit_text = (
		<Columns className={className + " margin-bottom0 small-padding"} align='left'>
				<Columns.Column><b>{T('sales-profit')}:</b> <Money signClassname={true} amount={profit || 0} aid={accountID} wrapper={DOM.span} />
					<br /> <b>{T('products-netcost')}:</b> <Money amount={net_price || 0} aid={accountID}  wrapper={DOM.span}/>
				</Columns.Column>
				<Columns.Column><b>{T('margin')}:</b> {margin}%  </Columns.Column>

			</Columns>
		);

	return (
			<List.Item style={{position: 'relative'}}>
				<Columns className={`${className} + margin-bottom-10`}>
					<Columns.Column  size="one-fifth" className="is-one-six" align="center">
						<Link to="/sales" onClick={() => AppStore.openModal(<ViewProductModal pid={product_id} />)}>
							<Image src={img} size={32} className="product-image"/>
						</Link>
					</Columns.Column>
					<Columns.Column align='left'>
						<Link to="/sales" onClick={() => AppStore.openModal(<ViewProductModal pid={product_id} />)}>
							<TypeProductTag typeProduct={product.type_product}/>{" "}
							{product_name}
							{product_name && ' | '}
							{WarehouseStore.getWarehouseName(mid)}
							{!!product.sku && ` | ${product.sku}`}
							{text_deleted}
							&nbsp;
							<span className='nowrap'>
								({quantity} {T('items-e')})
							</span>
						</Link>
					</Columns.Column>
				</Columns>
				{base_price !== amount && UserStore.getPermissionEnable("salesprice") &&<Columns className='margin-bottom-0'>
					<Columns.Column style={{textAlign: 'left'}}><b>{T('basic-price-label')}:</b>
					<Money amount={base_price || 0}
								 wrapper={DOM.span}/>
					</Columns.Column>
				</Columns>}
				{is_refund ? <b className="has-text-danger">{T('item-refunded')}</b> : sales_text}
				{User.getPermissionEnable("incomeinformation") && !is_refund ? profit_text : null}
				{!is_refund && productsLength > 1 && !isRefundButton && <div style={{position: "absolute", top: 0, right: 10}}>
						 <RefundProductButton status={status} data={data} orderID={orderID} aid={accountID} name={product_name}/>
				</div>}
			</List.Item>
	);
};

export default SalesProductRowMobile;