import React from "react";
import ProductsStore from "../../../../products/products-store";
import User from "../../../../../user/user-store";
import calcMargin from "../../../../../tools/calc-margin";
import List from "react-bulma-components/lib/components/list";
import Image from "react-bulma-components/lib/components/image";
import Columns from "react-bulma-components/lib/components/columns";
import {
  Control,
  Field,
  Label,
} from "react-bulma-components/lib/components/form";
import T from "../../../../../components/i18n";
import NumberInput from "../../../../../components/number-input/number-input";
import Icon from "react-bulma-components/lib/components/icon";
import InfoWrapper from "../../../../../components/info-wrapper";
import Money from "../../../../../components/money";
import DOM from "react-dom-factories";
import { instockMobileText, productNameMobile } from "../utils";
import {Button} from "react-bulma-components";
import AppStore from "../../../../../app-store";
import ChangeIndexModal
  from "../../../../../components/draggable-list/change-index-modal";

const FinishProductLineMobile = ({
  prod,
  mid,
  productData,
  currency,
  currencyRate,
  onChangeProductData,
  onChangeDeliveryPrice,
  onChangeRetailPrice,
  index,
  onChangeIndexItem
}) => {
  const pid = prod.id;
  const product = ProductsStore.getProductByMid(pid, mid);
  const name = productNameMobile(product);
  const instock = instockMobileText(product);
  const img = "/bimages/get/64-" + User.get("id") + "-" + pid + "-0.jpg";
  let margin = calcMargin(
    productData[pid]["raw_net_price"] + productData[pid]["fee_per_unit"],
    productData[pid]["retail_price"]
  );

  const shCurr = currency;
  const retailCurr = User.getCurrency();

  if (shCurr !== retailCurr) {
    margin = calcMargin(
      productData[pid]["raw_net_price"] * currencyRate +
        productData[pid]["fee_per_unit"],
      productData[pid]["retail_price"]
    );
  }

  const quantityReceived = productData[pid]["quantity_received"];
  const rawNetPrice = productData[pid]["raw_net_price"] || 0;
  const constDeliveryPerUnit = productData[pid]["fee_per_unit"] || 0;
  const currRate = currencyRate;
  let calcNewConstPrice =
    parseFloat(quantityReceived) *
    (parseFloat(rawNetPrice) + parseFloat(constDeliveryPerUnit));

  if (shCurr !== retailCurr) {
    calcNewConstPrice =
      parseFloat(quantityReceived) *
      (parseFloat(rawNetPrice) * parseFloat(currRate) +
        parseFloat(constDeliveryPerUnit));
  }


  const handleChangeItems = () => {
    AppStore.openModal(<ChangeIndexModal modal='changeIndex' index={index + 1} onChangeIndex={(newIndex) => onChangeIndexItem(newIndex, index)}/>)
  }

  return (
    <List.Item key={pid} align="left">
      <div className='display-flex-row'>
        <Button size='large' className='button-link-without-style' onClick={handleChangeItems}>
          {index + 1}
        </Button>
        <b>
          <Image src={img} size={32} className="align-left"/>
          {name}
        </b>
      </div>
      <hr/>
      <Columns className="is-mobile">
        <Columns.Column>
          <Label className="margin-top-10">
            {T("product-info-warehouse-one")}:<span> {instock}</span>
          </Label>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Label>{T("received")}: </Label>
            <Control iconRight>
              <NumberInput
                key={"quantity_received-" + pid}
                name={"quantity_received-" + pid}
                className="for-small-icon"
                type="number"
                min="0"
                onChange={(evt) =>
                  onChangeProductData(evt, "quantity_received")
                }
                value={productData[pid]["quantity_received"]}
              />
              <Icon align="right">
                <span className="small-text">{T("items-e")}</span>
              </Icon>
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field horizontal>
            <Label>
              <InfoWrapper info="change-delivery-shipment-tip">
                {T("delivery-delivery")} ({T("cost-for").toLocaleLowerCase()})
              </InfoWrapper>
              :
            </Label>
            <Control iconRight>
              <NumberInput
                key={"fee_per_unit-" + pid}
                name={"fee_per_unit-" + pid}
                className="for-small-icon"
                onChange={onChangeDeliveryPrice}
                value={productData[pid]["fee_per_unit"]}
              />
              <Icon align="right">
                <span className="small-text">{retailCurr}</span>
              </Icon>
            </Control>
          </Field>
        </Columns.Column>
      </Columns>
      {!User.getPermissionEnable("incomeinformation") ? null : (
        <Columns className="is-mobile">
          <Columns.Column>
            <Field>
              <Label>{T("new_netprice")}:</Label>
              <Control iconRight>
                <NumberInput
                  key={"pid-" + pid}
                  name={"pid-" + pid}
                  className="for-small-icon"
                  min="0.01"
                  onChange={(evt) => onChangeProductData(evt, "raw_net_price")}
                  value={productData[pid]["raw_net_price"]}
                />
                <Icon align="right">
                  <span className="small-text">{shCurr}</span>
                </Icon>
              </Control>
            </Field>
            <div
              align="left"
              style={{ marginTop: "-5px" }}
              className="has-text-black"
            >
              {T("margin")}: {margin}%
            </div>
          </Columns.Column>
          <Columns.Column>
            <Field horizontal>
              <Label>{T("products-price")}:</Label>
              <Control iconRight>
                <NumberInput
                  key={"retail_price-" + pid}
                  name={"retail_price-" + pid}
                  className="for-small-icon"
                  type="number"
                  min="0.01"
                  onChange={onChangeRetailPrice}
                  value={productData[pid]["retail_price"]}
                />
                <Icon align="right">
                  <span className="small-text">{retailCurr}</span>
                </Icon>
              </Control>
            </Field>
            <div align="right" style={{ marginTop: "-5px" }}>
              <b className="display-inline">{T("cost-of-sales")}: </b>
              <Money wrapper={DOM.span} amount={calcNewConstPrice} />
            </div>
          </Columns.Column>
        </Columns>
      )}
    </List.Item>
  );
};

export default FinishProductLineMobile;
