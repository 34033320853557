import React, { Component } from "react";
import DOM from "react-dom-factories";
import { LocalIcon } from "whitelables/wl-name/icons";
import SalesStore from "modules/sales/sales-store";
import Actions from "modules/sales/sales-actions";
import EditSaleModal from "modules/sales/edit-sales-modal";
import RefundModal from "modules/sales/refund-modal";
import ChangeAccount from "modules/sales/change-account";
import PrintModal from "modules/sales/print-modal";
import FinishReservModal from "modules/sales/finish-reserv-modal";
import ChangeStatusModal from "modules/sales/change-status-modal";
import ProcessDeliveryModal from "modules/sales/process-delivery-modal";
import ClientsStore from "modules/clients/clients-store";
import ExpensesStore from "modules/expenses/expenses-store";
import ProductsStore from "modules/products/products-store";
import WarehouseStore from "modules/warehouse/warehouse-store";
import DpsCheckModal from "modules/sales/modal/dps-check-modal/dps-check-modal";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import T from "components/i18n";
import Money from "components/money";
import InfoWrapper from "components/info-wrapper";
import OpenInsideModal from "components/modal/open-inside-modal";
import numberFormat from "tools/number-format";
import calcMargin from "tools/calc-margin";
import formatDate from "tools/format-date";
import { Link } from "react-router-dom";
import User from "user/user-store";
import Columns from "react-bulma-components/lib/components/columns";
import Dropdown from "react-bulma-components/lib/components/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrdersStore from "modules/orders/orders-store";
import AppStore from "../../../app-store";
import ChannelBlock from "../../../components/channel-block/channel-block";
import { notIsBasicType } from "../common/utils";
import {
  Checkbox,
  Control,
  Field,
} from "react-bulma-components/lib/components/form";
import SelectStore from "../select-store";
import ClientViewModal from "../../clients/modal/client-view/client-view-modal";
import SaleViewModal from "../modal/sale-view/sale-view-modal";
import ViewProductModal
  from "../../products/modal/view-product/view-product-modal";
import PrepaymentFiscalization
  from "../modal/prepayment-fiscalization/prepayment-fiscalization";
import AddSalesModal from "../add-sales-modal";
import UserStore from "user/user-store";
import TypeProductTag
  from "../../../components/type-product-tag/type-product-tag";
import {Button} from "react-bulma-components";
import DpkTag from "../../../components/dpk-tag/dpk-tag";
import ClientInfoRow from "../../../components/client-info-row/client-info-row";
import ClientDelivery from "../common/client-delivery";
import AddCommentModal from "../modal/add-comment-modal/add-comment-modal";

class SalesRow extends Component {
  constructor(props) {
    super(props);

    this.order_name = "";

    const { data } = this.props;

    this.isOne = data["items"].length === 1;
    this.state = {
      showInside: false,
      isLoadingPrint: false
    };

    // this.showInside = this.showInside.bind(this);
    // this.mobileRow = this.mobileRow.bind(this);
    // this.getProductRow = this.getProductRow.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.triggerIsOpen !== this.props.triggerIsOpen) {
      this.setState({
        showInside: this.props.triggerIsOpen,
      });
    }
  }

  onChangeCheckbox(id) {
    SelectStore.onSelectGroup(id);
  }

  showInside(e) {
    if (
      e.target.className === "big-dot" ||
      e.target.localName === "path" ||
      e.target.type === "checkbox"
    ) {
      return false;
    }

    this.setState({
      showInside: !this.state.showInside,
    });
  }

  deleteSale(oid, _pr) {
    const { data } = this.props;

    if (data["order_status"] === "saled") {
      _pr = false;
    }

    confirmAlert({
      title: T("confirm-delete"),
      message: T("are-you-sure-to-delete-this"),
      buttons: [
        {
          label: T("delete"),
          onClick: () => Actions.deleteSale(oid, _pr),
        },
        {
          label: T("no-delete"),
          onClick: () => {},
        },
      ],
    });
  }

  getRefundItemButton(data, item, name, count_items) {
    if (!User.getPermissionEnable("refund-products")) {
      return null;
    }
    if (data["order_status"] !== "saled" || count_items === 1) return;
    let request = {
      fromAccount: data["account_id"],
      amount: item["amount"],
      product_name: name,
      oid: data["oid"],
      oiid: item["oiid"],
    };
    return (
      <OpenInsideModal size="small" color="success" renderAs={"a"} icon="undo">
        <RefundModal modal="refund-reserv" {...request} />
      </OpenInsideModal>
    );
  }

  _get_refund() {
    if (!User.getPermissionEnable("refund-products")) {
      return null;
    }

    const { data } = this.props;

    let amount = data["amount_sale"];

    if (data.order_status === "refund") {
      amount = amount - (data.prepaid_amount || 0);
    }

    return (
      <OpenInsideModal
        size={"small"}
        renderAs="p"
        color="warning"
        text={<span className="main_text">{T("refund")}</span>}
        icon="undo"
      >
        <RefundModal
          fromAccount={data.account_id}
          amount={amount}
          product_name={this.order_name}
          oid={data.oid}
          dps={Array.isArray(data.dps)}
        />
      </OpenInsideModal>
    );
  }

  getRefundButton() {
    if (!User.getPermissionEnable("refund-products")) {
      return null;
    }

    const { data } = this.props;
    // if (
    //   ExpensesStore.isDebt(data["account_id"]) ||
    //   ExpensesStore.isDebt(data["prapaid_account_id"])
    // ) {
    //   return null;
    // }

    return (
      <Dropdown.Item key={"rf-" + data.oid} value={T("refund")}>
        {this._get_refund()}
      </Dropdown.Item>
    );
  }

  getActions(data) {
    let actions = [],
      prepaid = data.prepaid_amount || 0,
      rfun = this.getRefundButton(),
      main_button = null;

    if(this.props.isRefund && UserStore.getPermissionEnable("edit-sales")) {
      return <Button className='button-link-icon' onClick={() => AppStore.openModal(<EditSaleModal oid={data.oid} modal="sale-modal" isRefund/>)}>
        <FontAwesomeIcon icon='edit'/>
      </Button>
    } else if (this.props.isRefund) {
      return <></>
    }

    if (
      ["delivery", "delivering", 'arrived'].indexOf(data.order_status) > -1 ||
      !SalesStore.isDefaultStatus(data.order_status)
    ) {
      let deliv = (
        <OpenInsideModal
          size="small"
          renderAs="p"
          color="success"
          text={<span className="main_text">{T("to-send")}</span>}
          local_icon="truck-fast"
        >
          <ProcessDeliveryModal
            aid={data.account_id}
            prepaid={prepaid}
            total_order={data.amount_sale}
            prepaid_account_id={data.prapaid_account_id}
            clients_delivery_id={data.clients_delivery_id}
            oid={data.oid}
          />
        </OpenInsideModal>
      );
      actions.push(
        <Dropdown.Item key="to-send" value={T("to-send")}>
          {deliv}
        </Dropdown.Item>
      );
      main_button = deliv;
    } else if (data.order_status === "refund" && rfun) {
      actions.push(rfun);
      actions.push(<Dropdown.Item value={T('change-order-status')} key='status' >
        <OpenInsideModal
          renderAs="p"
          color="success"
          local_icon="circle-dotted"
          text={<span className="main_text">{T('change-order-status')}</span>}
        >
          <ChangeStatusModal
            modal="change-status"
            oid={data["oid"]}
            status={data["order_status"]}
          />
        </OpenInsideModal>
      </Dropdown.Item>)
      main_button = this._get_refund();
    } else if (data.order_status === "cod") {
      let cod = (
        <OpenInsideModal
          renderAs="p"
          color="success"
          icon="money-bill-alt"
          text={<span className="main_text">{T("pick-up-transfer")}</span>}
        >
          <ChangeAccount
            fromAccount={{ value: data.account_id }}
            amount={data.amount_sale - prepaid}
            complete={true}
            oid={data.oid}
          />
        </OpenInsideModal>
      );
      actions.push(
        <Dropdown.Item key="pt" value={"pick-up-transfer"}>
          {cod}
        </Dropdown.Item>
      );
      main_button = cod;
    } else if (data.order_status === "reserve_sale") {
      let res = (
        <OpenInsideModal
          renderAs="p"
          color="success"
          local_icon="truck-fast"
          text={<span className="main_text">{T("finish-reserv-title")}</span>}
        >
          <FinishReservModal
            number={data.order_number || data.oid}
            aid={data.prapaid_account_id || data.account_id}
            prepaid={prepaid}
            total_order={data.amount_sale}
            oid={data.oid}
          />
        </OpenInsideModal>
      );
      actions.push(
        <Dropdown.Item key="fr" value={T("finish-reserv-title")}>
          {res}
        </Dropdown.Item>
      );
      main_button = res;
    }

    if (User.getModuleEnable("slip")) {
      let print = (
        <OpenInsideModal
          renderAs="p"
          color="success"
          local_icon="download"
          text={<span className="main_text">{T("documents")}</span>}
        >
          <PrintModal oid={data.oid} dps={data["dps"]} />
        </OpenInsideModal>
      );
      actions.push(
        <Dropdown.Item key={"documents"} value={T("documents")}>
          {print}
        </Dropdown.Item>
      );
      if (!main_button) {
        main_button = print;
      }

      if (
        User.isButtonDps({data: data['dps'], orderID: data.oid}) &&
        !ExpensesStore.isDebt(data.account_id)
      ) {
        let dps = (
          <OpenInsideModal
            renderAs="p"
            color="success"
            local_icon="download"
            text={<span className="main_text">{T("send-to-dps")}</span>}
          >
            <DpsCheckModal oid={data.oid} modal='fiscal' />
          </OpenInsideModal>
        );

        actions.push(
          <Dropdown.Item key={"send-to-dps"} value={T("send-to-dps")}>
            {dps}
          </Dropdown.Item>
        );
      }



      if(User.isButtonPrepaymentDps({data: data['dps'], prepaid, accountPrepaid: data.prapaid_account_id, orderID: data.oid})) {
        actions.push(
          <Dropdown.Item key={T('send-to-dps-prepaid')} value={T("send-to-dps-prepaid")}>
            <OpenInsideModal
              renderAs="p"
              color="success"
              local_icon="download"
              text={<span className="main_text">{T("send-to-dps-prepaid")}</span>}
            >
              <PrepaymentFiscalization modal='fiscal' oid={data.oid} amount={prepaid} accountId={data.prapaid_account_id} orderNumber={data.order_number}/>
            </OpenInsideModal>
          </Dropdown.Item>
        )
      }
    }

    if(UserStore.getPermissionEnable("edit-sales")) {
      actions.push(
        <Dropdown.Item key="btn-edit" value={T("btn-edit")}>
          <OpenInsideModal renderAs="p" icon="edit" text={T("btn-edit")}>
            <EditSaleModal oid={data.oid} modal="sale-modal"/>
          </OpenInsideModal>
        </Dropdown.Item>
      );
    }

    actions.push(
      <Dropdown.Item key={"copy-sale"} value={T("copy-sale")}>
        <OpenInsideModal renderAs="p" icon="copy" text={T("copy-sale")}>
          <AddSalesModal {...data} isCopy modal="sale-modal"/>
        </OpenInsideModal>
      </Dropdown.Item>)

    if (data.order_status === "saled" && rfun) {
      actions.push(rfun);
    }

    if (User.getPermissionEnable("deletesales")) {
      actions.push(
        <Dropdown.Item key="del" value={T("del")}>
          <div onClick={() => this.deleteSale(data.oid)}>
            <span>
              <FontAwesomeIcon icon={"trash-alt"} size="1x" />
              &nbsp;
            </span>
            {T("del")}
          </div>
        </Dropdown.Item>
      );
    }

    return (
      <Columns
        className="sales-actions"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Columns.Column className="main_button">{main_button}</Columns.Column>
        <Columns.Column>
          <Dropdown
            right
            className="fixed-dropdown dot"
            style={{ marginBottom: "10px" }}
            onClick={(e) => e.stopPropagation()}
            label={<LocalIcon icon="ellipsis" />}
          >
            {actions}
          </Dropdown>
        </Columns.Column>
      </Columns>
    );
  }

  getClient(client_id) {
    if (client_id) {
      const client = ClientsStore.getClientById(client_id);
      if (!client) {
        return "";
      }

      ClientsStore.queueClientsCount(client_id)

      const getClientCount = ClientsStore.getClientCountById(client_id)

      return (
        <Button className='button-link-without-style' onClick={(e) =>{
          e.stopPropagation(); AppStore.openModal(<ClientViewModal clientID={client_id} />)}}
          >
          <span>
            {client.name}&ensp;{client && !!getClientCount.non_delete_orders_count && <b>({getClientCount.non_delete_orders_count})</b>}
          </span>
        </Button>
      );
    }

    return null
  }

  getAccounts(data) {
    let response = [],
      prepaid_aid = data["prapaid_account_id"],
      prepaid = data["prepaid_amount"],
      aid = data["account_id"],
      total = data["amount_sale"],
      two_account = prepaid > 0,
      second_part = total - prepaid;
    const isRefund = this.props.isRefund

    if (two_account) {
      response.push(
        isRefund ? T(ExpensesStore.getAccountName(prepaid_aid)) : <Link
          key="o0"
          to="#"
          onClick={() =>
            AppStore.openModal(
              <ChangeAccount
                fromAccount={{ value: prepaid_aid }}
                amount={numberFormat(prepaid)}
                oid={data["oid"]}
                isPrepaid
              />
            )
          }
        >
          {" "}
          {T(ExpensesStore.getAccountName(prepaid_aid))}
        </Link>
      );

      response.push(
        <span key="pr-i">
          :{" "}
          <Money
            aid={data["prepaid_aid"]}
            amount={prepaid || 0}
            wrapper={DOM.span}
          />
          <br />
        </span>
      );
    }

    response.push(
      isRefund ? T(ExpensesStore.getAccountName(aid)) : <Link
        key="o1"
        to="#"
        onClick={() =>
          AppStore.openModal(
            <ChangeAccount
              fromAccount={{ value: aid }}
              amount={second_part}
              oid={data["oid"]}
            />
          )
        }
      >
        {T(ExpensesStore.getAccountName(aid))}
      </Link>
    );

    if (two_account) {
      response.push(
        <span key="secpr">
          : <Money aid={aid} amount={second_part || 0} wrapper={DOM.span} />
        </span>
      );
    }

    return response;
  }

  getChannels(channels) {
    let response = [];

    for (let i in channels) {
      let channel_id = channels[i];
      response.push(<ChannelBlock key={"ch-" + i} channel_id={channel_id} />);
    }

    return response;
  }

  renderAdditionalField(afParse) {
    if (afParse) {
      return Object.keys(afParse).reduce((acc, key, index) => {
        const value = afParse[key];
        if (value) {
          acc.push(
            <div key={`row-${index}+${key}`}>
              <span className="silver">{key}: </span> {afParse[key]}
            </div>
          );
        }

        return acc;
      }, []);
    }
    return null;
  }

  openViewSale(evt, saleID) {
    evt.stopPropagation();
    AppStore.openModal(<SaleViewModal saleID={saleID} isRefund={this.props.isRefund}/>);
  }

  renderFieldName(data, quantity) {
    return (
      <>
        <div>
          <DpkTag orderID={data.oid} status={data.order_status}/>
        </div>
        <span>
          <Button className='button-link-without-style underline' style={{userSelect: 'text'}} onClick={(evt) => this.openViewSale(evt, data.oid)}>
             {data["order_number_text"]}
          </Button>
          {" "}
          <span className="silver nowrap">
            {T("items-count", {count: data["items"].length})} ({quantity}{" "}
            {T("items-e")})
          </span>
        </span>

        <br/>
        <span className="silver margin-right-5">
          {formatDate(data["purchase_date"])}
        </span>
        {this.getChannels(data["channel_id"])}
      </>
    );
  }

  renderResponseUser(data) {
    return User.getResponsebleName(data["responsible_user_id"]);
  }

  renderClientDelivery(data) {
    return <ClientDelivery
      clientID={data["client_id"]}
      clientDeliveryInfo={data["delivery_info"]}
      clientDeliverID={data["clients_delivery_id"]}
      trackingNumber={data["tracking_number"]}
    />
  }

  renderSalePrice(data, profit) {
    let discount_row = "";

    if (data["discount"]) {
      discount_row = (
        <span className="silver">
          {T("sales-discount")}: {data["discount"]}
          {data["discount_type"] === "stable"
            ? ` ${User.getCurrency()}`
            : data["discount_type"]}{" "}
          <br />
        </span>
      );
    }

    return (
      User.getPermissionEnable("salesprice") && (
        <>
          <Money aid={data["account_id"]} amount={data["amount_sale"] || 0}/>
          {discount_row}
          {User.getPermissionEnable("incomeinformation") && (
            <>
              <span className="silver">{T("sales-profit")}: </span>
              <b>
                <Money
                  signClassname={true}
                  aid={data["account_id"]}
                  wrapper={DOM.span}
                  amount={profit || 0}
                />
              </b>
            </>
          )}
        </>
      )
    );
  }

  renderStatus(data) {
    const isNotDps = User.isButtonDps({data: data['dps'], orderID: data['oid']})
    const isNotDpsPre = User.isButtonPrepaymentDps({data: data['dps'], prepaid: data.prepaid_amount, accountPrepaid: data.account_prepaid, orderID: data['oid']})

    if(this.props.isRefund) {
      return <div
        className={"order-status order-status-refund"}
      >
        {T("status-" + data["order_status"])}
      </div>
    }
    return (
      <>
        {['cod', 'refund', 'reserve_sale'].indexOf(data["order_status"]) > -1 ? (
          <div
            className={
              "order-status order-status-" +
              data["order_status"] +
              " margin-left-0"
            }
          >
            {T("status-" + data["order_status"])}
          </div>
        ) : (
          <OpenInsideModal
            size="small"
            text={
              SalesStore.isDefaultStatus(data["order_status"])
                ? "status-" + data["order_status"]
                : OrdersStore.getStatusNameById(data["order_status"])
            }
            className={"order-status order-status-" + data["order_status"]}
          >
            <ChangeStatusModal
              modal="change-status"
              oid={data["oid"]}
              status={data["order_status"]}
            />
          </OpenInsideModal>
        )}
        <div align="center" style={{ width: "145px", marginLeft: 0 }}>
          {(!data['dps'] || data['dps']?.length) && data.prepaid_amount > 0  && (
            <small className="has-text-danger">
              {!data['dps'] && isNotDps && isNotDpsPre && T("not-dps")}
              {!data['dps'] && !isNotDps && !isNotDpsPre && UserStore.getPRROList(false, true) &&  T("not-dps")}
              {isNotDps && data.prepaid_amount > 0 && !isNotDpsPre && T("partial-dps")}
            </small>
          )}
          {(!data['dps'] || data['dps']?.length) && data.prepaid_amount <= 0  && (
            <small className="has-text-danger">
              {!data['dps'] && !isNotDps && UserStore.getPRROList(false, true) &&  T("not-dps")}
              {!data['dps'] && isNotDps && T("not-dps")}
            </small>
          )}
        </div>
      </>
    );
  }

  renderColumn(quantity, profit) {
    const { activeColumn, data } = this.props;
    const sorting = activeColumn?.sort((a, b) => a?.position - b?.position);
    const response = [];

    const keyMap = {
      purchase: this.renderFieldName(data, quantity),
      "who-add-sale": this.renderResponseUser(data),
      customer: <ClientInfoRow clientID={data['client_id']} withTags/>,
      delivery: this.renderClientDelivery(data),
      account: this.getAccounts(data),
      "payment-pay": User.getPermissionEnable("salesprice")
        ? this.renderSalePrice(data, profit)
        : false,
      status: this.renderStatus(data),
      "reports-actions": this.getActions(data),
    };

    sorting.forEach((item) => {
      if (item?.is_active && item.label && keyMap[item?.label]) {
        const isAction = item.label === "reports-actions";
        response.push(
          <td
            align={isAction ? "center" : "left"}
            style={isAction ? { width: "90px" } : {}}
            key={item.label}
          >
            {keyMap[item?.label]}
          </td>
        );
      }
    });

    return response;
  }

  render() {
    const { data, num } = this.props;
    let quantity = 0,
      net_price = 0,
      profit = 0,
      taxs = 0,
      channel_taxs = 0;

    for (let i in data["items"]) {
      const item = data["items"][i];
      quantity += parseFloat(item["quantity"]);
      net_price += parseFloat(item["net_price"]);
      profit += parseFloat(item["profit"]);
      taxs += parseFloat(item["tax"]);
      channel_taxs += parseFloat(item["channel_tax"]);
    }

    let response = [];

    let className = "click_element";
    if (this.state.showInside) {
      className += " selected-border";
    }

    className += " is-one-";
    className += num % 2 === 0 ? "even" : "odd";

    response.push(
      <tr
        key={data["order_number_text"]}
        className={className}
        onClick={(e) => this.showInside(e)}
      >
        <td>
          {this.props.isCheckbox && (
            <Field>
              <Control>
                <Checkbox
                  name="is_products"
                  onChange={() => this.onChangeCheckbox(data.oid)}
                  checked={SelectStore.getIsSelectById(data.oid)}
                />
              </Control>
            </Field>
          )}
        </td>
        {this.renderColumn(quantity, profit)}
      </tr>
    );

    const additionalField = this.renderAdditionalField(data?.af);

    if (this.state.showInside) {
      let inside_response = [],
          taxes = [];

      if (taxs > 0) {
        taxes.push(
          <span key="chtxtaxs">
            {T("bank_comission")}: <Money amount={taxs} wrapper={DOM.span} />{" "}
            <br />
          </span>
        );
      }
      if (channel_taxs > 0) {
        taxes.push(
          <span key="chtxtcaxs">
            {T("commission-sales-chanel")}:{" "}
            <Money amount={channel_taxs} wrapper={DOM.span} />
          </span>
        );
      }

      const customColumns = UserStore.getCustomColumns("pr-sales-column")
      const isWhoAddSaleActive = customColumns['who-add-sale']?.is_active

        inside_response.push(
          <tr
            className="inside-sale info-row"
            key={"in-" + data["order_number_text"]}
          >
            <td></td>
            <td colSpan="2" width="55%">
              {!!data["clients_delivery_id"] && (
                <span>
                  <span className="silver">{T("delivery")}:</span>{" "}
                  {ClientsStore.getClientDeliveryNameById(
                    data["client_id"],
                    data["clients_delivery_id"],
                    User.getLang()
                  )}{" "}
                  <br />
                </span>
              )}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <span className="silver">{T("comment")}: </span>
                  {!!data["comment"] ? <>
                      <span>{data["comment"]}</span>
                      <Button className='button-link-icon' style={{display: 'inline', padding: '0 5px', marginBottom: 3, marginTop: 0}} rounded
                            onClick={() => AppStore.openModal(<AddCommentModal
                              comment={data["comment"]} oid={data['oid']}/>)}>
                      {" "}<FontAwesomeIcon icon='edit'/>
                    </Button>
                    </>
                    :
                    <>
                    <span className='has-text-success cursor-pointer'
                          onClick={() => AppStore.openModal(<AddCommentModal
                            comment={data["comment"]} oid={data['oid']}/>)}>
                      <FontAwesomeIcon icon='plus-circle'/>
                    </span>
                    {/*<Button className='button-link-without-style' color='success' rounded size='small' onClick={() => AppStore.openModal(<AddCommentModal comment={data["comment"]} oid={data['oid']}/>)}>*/}
                      {/*{data['comment'] ? T('edit-comment') : T('add-comment')}*/}
                    {/*</Button>*/}
                  </>
                  }
                </div>
                {!!additionalField?.length && additionalField}
              </div>
              {!isWhoAddSaleActive && <span><span className="silver">{T('who-add-sale')}: </span>{User.getResponsebleName(data["responsible_user_id"])} </span>}
            </td>
            <td align="left">
              {User.getPermissionEnable("incomeinformation") && (
                data["items"].length > 1 && <>
                <span className="silver">{T("netcost")}: </span>{" "}
                <Money amount={net_price || 0} /> <br />
                <span className="silver">{T("sales-profit")}: </span>
                <b>
                  <Money
                    signClassname={true}
                    aid={data["account_id"]}
                    amount={profit || 0}
                  />
                </b>{" "}
                ({calcMargin(net_price, data["amount_sale"])}%)
              </>)}
            </td>
            <td>{taxes}</td>
            <td colSpan="2">
            </td>
          </tr>
        );

      let count_items = data["items"].length;

      for (let i in data["items"]) {
        const item = data["items"][i],
          __text_delete = (
            <span className="has-text-danger"> {T("product_deleted")} </span>
          );
        let text_deleted = item["product_deleted"] === 1 ? __text_delete : "";
        let pr_d = ProductsStore.getProductByMid(
            item["product_id"],
            item["mid"]
          ),
          product_name = pr_d["name"],
          margin = calcMargin(item["net_price"], item["amount"]),
          tax = [];

        if (!pr_d) {
          text_deleted = __text_delete;
        }
        if(pr_d && !pr_d.stock[item.mid]) {
          text_deleted = __text_delete;
        }

        if (item["tax"] > 0) {
          tax.push(
            <span key="cbtx">
              {T("bank_comission")}:{" "}
              <Money amount={item["tax"]} wrapper={DOM.span} /> <br />
            </span>
          );
        }
        if (item["channel_tax"] > 0) {
          tax.push(
            <span key="chtx">
              {T("commission-sales-chanel")}:{" "}
              <Money amount={item["channel_tax"]} wrapper={DOM.span} />
            </span>
          );
        }

        const handleOpenViewProduct = () => {
          if(!text_deleted || pr_d) {
            AppStore.openModal(
              <ViewProductModal pid={item["product_id"]} />
            )
          }
        }
        inside_response.push(
          <tr
            className="inside-sale"
            key={"p-" + data["order_number_text"] + "-" + item["product_id"]}
          >
            <td></td>
            <td colSpan="2">
              <Columns>
                <Columns.Column className="is-one-64">
                  <Button className='button-link-without-style' onClick={handleOpenViewProduct}>
                    <img
                      alt={product_name}
                      src={ProductsStore.makeImageUrl(
                        item["product_id"],
                        0,
                        64
                      )}
                      className="product-image"
                    />
                  </Button>
                </Columns.Column>
                <Columns.Column>
                  <Button className='button-link-without-style' onClick={handleOpenViewProduct}>
                    <TypeProductTag typeProduct={pr_d["type_product"]}/> {product_name} {!pr_d && text_deleted}
                  </Button>
                  <br />
                  {WarehouseStore.getWarehouseName(item["mid"])} |{" "}
                  {ProductsStore.getCategoryName(pr_d["category_id"])}{" "}
                  {pr_d["sku"] ? "| " + pr_d["sku"] : null}
                </Columns.Column>
              </Columns>
            </td>
            {User.getPermissionEnable("incomeinformation") ? (
              <td>
                <span className="silver">{T("netcost")}: </span>{" "}
                <Money
                  title={T("commission") + ": " + (item["tax"] || 0)}
                  amount={(item["net_price"] || 0) + (item["tax"] || 0)}
                  wrapper={DOM.span}
                />{" "}
                <br />
                {tax.length > 0 ? (
                  <>
                    <InfoWrapper info={tax} defaultPosition="-5">
                      <span className="silver">{T("sales-profit")}: </span>
                      <b>
                        <Money
                          signClassname={true}
                          aid={data["account_id"]}
                          amount={item["profit"] || 0}
                        />
                      </b>
                    </InfoWrapper>{" "}
                    ({margin}%){" "}
                  </>
                ) : (
                  <>
                    <span className="silver">{T("sales-profit")}: </span>
                    <b>
                      <Money
                        signClassname={true}
                        aid={data["account_id"]}
                        amount={item["profit"] || 0}
                      />
                    </b>
                    ({margin}%)
                  </>
                )}
              </td>
            ) : null}
            <td>
              {item["base_price"] !== item["amount"] && UserStore.getPermissionEnable("salesprice") && (
                <span>
                  <span className="silver">{T("basic-price-label")}: </span>{" "}
                  <Money
                    aid={data["account_id"]}
                    amount={item["base_price"] || 0}
                  />
                  <br />
                </span>
              )}
              {UserStore.getPermissionEnable("salesprice") && <>
                <span className="silver">{T("netprice-price")}: </span>{" "}
                <Money aid={data["account_id"]} amount={item["amount"] || 0} />{" "}
                {!!item?.type_price && notIsBasicType(item?.type_price) && (
                  <>({item?.type_price})</>
                )}{" "}
              </>}
              <br />
              {!!item?.discount && (
                <>
                  <span className="silver">{T("sales-discount")}: </span>{" "}
                  {item?.discount < 0 ? <Money aid={data["account_id"]} amount={Math.abs(item.discount) || 0} /> : `${item.discount}%`}<br />
                </>
              )}
              <span className="nowrap">
                <span className="silver">{T("quantity")}: </span>{" "}
                {ProductsStore.getStockUnits(item["quantity"], pr_d["units"])}
              </span>
            </td>
            <td>
              {item["is_refund"] ? (
                <b className="has-text-danger">{T("item-refunded")}</b>
              ) : (
                this.getRefundItemButton(data, item, product_name, count_items)
              )}
            </td>
          </tr>
        );
      }
      let insideClassName = "selected-border";
      if (this.isOne) {
        insideClassName += " is-one-";
        insideClassName += num % 2 === 0 ? "even" : "odd";
      }
      response.push(
        <tr className={insideClassName} key="bor">
          <td colSpan="9">
            <table width="100%" className="row-tables">
              <tbody>{inside_response}</tbody>
            </table>
          </td>
        </tr>
      );
    }
    return response;
  }
}

export default SalesRow;
