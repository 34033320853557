import React, {useState} from 'react'
import Card from "react-bulma-components/lib/components/card";
import Columns from "react-bulma-components/lib/components/columns";
import {
	Checkbox,
	Control,
	Field
} from "react-bulma-components/lib/components/form";
import User from "../../../../user/user-store";
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import EditProductsModal from "../../edit-products-modal";
import T from "../../../../components/i18n";
import {getStockMobile} from "../../common/function";
import Button from "react-bulma-components/lib/components/button";
import ProductStore from "../../products-store";
import Image from "react-bulma-components/lib/components/image";
import UserStore from "../../../../user/user-store";
import SalesStore from "../../../sales/sales-store";
import LightActions from "../../light-actions";
import LightStore from "../../light-store";
import SingleRenderMobile from "./single-render-mobile";
import TypeProductTag
	from "../../../../components/type-product-tag/type-product-tag";

const NewProductRowVariableMobile = ({data}) => {
	const [isOpen, setIsOpen] = useState(false)
	const firstDataItem = data[0]

	let img = "/bimages/get/64-" + User.get("id") + "-" + firstDataItem.parent_id + "-0.jpg";

	if (ProductStore.needRemoveImageCache(data.id)) {
		img += "?" + Math.random();
	}

	const img_o = <Image src={img} size={64} />;

	const info = [];
	info.push("ID: " + firstDataItem.parent_id);
	if (firstDataItem["brand"] && firstDataItem["brand"] !== "") {
		info.push(T("product-brand") + ": " + firstDataItem["brand"]);
	}
	if (firstDataItem["category_id"] && firstDataItem["category_id"] !== "") {
		info.push(
			T("category") + ": " + ProductStore.getCategoryName(firstDataItem["category_id"])
		);
	}

	const sumInstock = data.reduce((acc, cur) => {
		const sumStockCalc = Object.values(cur.stock || {}).reduce(
			(accStock, curStock) => {
				accStock.quantity += curStock.quantity;
				accStock.waiting += curStock.waiting;
				accStock.instock += curStock.instock;

				const isMinMax = UserStore.isMinimalStock({mid: curStock.marketplace_id, pid: curStock.product_id, instock: curStock.instock});

				if(isMinMax) {
					accStock.isMinMaxStock = true
				}

				const salesReport = SalesStore.getSaleReportsByProduct(
					curStock.product_id,
					curStock.marketplace_id
				);
				accStock.items_count += salesReport.items_count;

				accStock.childrenIdsWithMid.push(`${curStock.product_id},${curStock.marketplace_id}`);

				return accStock;
			},
			{ waiting: 0, instock: 0, quantity: 0, items_count: 0, isMinMaxStock: false, childrenIdsWithMid: [] }
		);

		acc.waiting += sumStockCalc.waiting
		acc.instock += sumStockCalc.instock
		acc.quantity += sumStockCalc.quantity
		acc.items_count += sumStockCalc.items_count

		if(sumStockCalc.isMinMaxStock) {
			acc.isMinMaxStock = true
		}

		sumStockCalc.childrenIdsWithMid.forEach(item => {
			acc.childrenIdsWithMid.push(item)
		})

		return acc
	},{ waiting: 0, instock: 0, quantity: 0, items_count: 0, isMinMaxStock: false, childrenIdsWithMid: [] })

	const handleChangeCheckbox = (evt) => {
		const value = evt.target.checked;

		LightActions.setVariableProductsGroup(sumInstock.childrenIdsWithMid, value)
	}

	const expirationDate = ProductStore.theExpirationDateByPid(data.id)


	return (
		<tr>
			<td
				colSpan="5"
				className={`${isOpen ? "has-background-light-black padding-bottom-10" : ""}`}
			>
				<Card onClick={() => setIsOpen(prev => !prev)}
							style={isOpen ? {marginBottom: 10} : {}}
							className={`${data.type_product === 4 ? 'services-border' : ''} ${expirationDate ? expirationDate : ''}`}>
					<Card.Content
						className={isOpen ? `${expirationDate ? expirationDate : 'has-background-light-black'}` : ""}>
						<Columns className="is-mobile product-mobile">
							<Columns.Column size="one-quarter">
								<div className="display-flex">
									<Field className="absolute">
										<Control>
											<Checkbox
												name="is_products"
												onChange={handleChangeCheckbox}
												onClick={(evt) => evt.stopPropagation()}
												checked={LightStore.getCheckedParentChecked(sumInstock.childrenIdsWithMid)}
											/>
										</Control>
									</Field>
									{!User.getPermissionEnable("editproducts") ? (
										<div>{img_o}</div>
									) : (
										<OpenInsideModal
											size="small"
											text=""
											img={img_o}
											renderAs="b"
										>
											<EditProductsModal pid={firstDataItem.parent_id} step={1}/>
										</OpenInsideModal>
									)}
								</div>
							</Columns.Column>
							<Columns.Column className="margin-left-5">
								<b className="product-name">
									<b>
										<p className="product-name">
											<b><TypeProductTag
												typeProduct={data.type_product}/> {firstDataItem.real_name} ({T('variable-count', {count: data.length})})</b>
										</p>
									</b>
									{!isOpen && (firstDataItem.type_product === 4 ? (
										<span className="subtitle">
                      {T('available')}: {" "}
											<strong>
                        <span className='text-success'>
                          {"∞ "}
                        </span>
												{T("items-e")}
                      </strong>
                    </span>
									) : (
										<span className="subtitle">
                      {T("available")}:{" "}
											<strong>
                        {getStockMobile(
													sumInstock.instock,
													sumInstock.quantity,
													sumInstock.waiting,
													false,
													false,
													true,
													sumInstock.isMinMaxStock
												)}
												{T("items-e")}
                      </strong>
                    </span>
									))}
								</b>
							</Columns.Column>
						</Columns>
						{isOpen ? (
							<Button.Group position="right">
								{!User.getPermissionEnable("deleteproducts") ? (
									""
								) : (
									<OpenInsideModal size="small" text="edit-btn">
										<EditProductsModal pid={firstDataItem.parent_id} step={0}/>
									</OpenInsideModal>
								)}
							</Button.Group>
						) : null}
					</Card.Content>
				</Card>
				{isOpen && (
					<table style={{width: '100%'}}>
						<tbody>
							{data.map(item => <SingleRenderMobile key={item.id} data={item} isVariable/>)}
						</tbody>
					</table>
				)}
			</td>
		</tr>
	);
};

export default NewProductRowVariableMobile;